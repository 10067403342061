<template>
  <el-dialog title="Adauga variabila" :visible.sync="showPopup" append-to-body width="75%" :close-on-click-modal='false'>
    <el-form label-position="top" @submit.prevent="save()">
      <el-form-item label="Variabile">
        <el-button 
          style="width: 100%; margin: 0" 
          v-for="(variable, index) in FilteredVariables" 
          :key="index" 
          @click="addVariable(variable.Nume, variable.Html)" 
          :type="ExistingVars.includes(variable) ? 'info' : ''">
          {{variable.Nume}}
        </el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" >
      <el-button @click="showPopup = false">Anuleaza</el-button>
    </span>
  </el-dialog>
</template>

<script>
import BasePage from "@/pages/BasePage"
import { EventBus } from '../../main'
import $ from 'jquery'

export default {
  extends: BasePage,
  name: "AddVariable",
  data () { 
    return {
      Variables: [],
      Filter: '',
      FilteredVariables: [],
      ExistingVars: [],
      focused_element: {},
      selectionInfo: {},
      parentnode: {},
      textnode: {},
      selection_start_index: 0,
      showPopup: false
    }
  },
  methods: {
    show () {
      let ExistingVars = []
      $(".note-editor .variabila").each((index, value) => {
        var text = $.trim($(value).text())
        if (ExistingVars.indexOf(text) == -1 && text != "") {
          ExistingVars.push(text)
        }
      })
      this.ExistingVars = ExistingVars

      this.showPopup = true
    },
    addVariable(varName, html) {
      this.$nextTick(() => {
        $(".note-editable").focus()

        this.$nextTick(() => {
          $('#summernote').summernote('restoreRange')
          $("#summernote").summernote('pasteHTML', html)
        })
      })

      this.showPopup = false
    },
    filterVariables() {
      if (!this.Filter || this.Filter.length == 0) {
        this.FilteredVariables = this.Variables
        return
      }

      this.FilteredVariables = this.Variables.filter((variable) => {
        return variable.Nume.toUpperCase().includes(this.Filter.toUpperCase())
      })
    }
  },
  async mounted() {
    let response = await this.post("formulare/get_variabiles", {})
    this.Variables = response.Variables
    this.filterVariables()
  }

  // async mounted() {
  //   let response = await this.post("variabile/get_variabile", {})
  //   this.Variables = response.Variabile
  //   this.filterVariables()
  // }
}
</script>
<style lang="less" scoped>
</style>
