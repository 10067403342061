<template>
  	<el-dialog title="Formular" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false' :close-on-press-escape="false">
		<el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model='selectedObject' ref='selectedObject' @submit.prevent="save" v-loading="loadingVisible" >
			
			<el-row :gutter="15" class="formular-draggable">
				<el-col :span="8">
					<el-card :style="tabelListaHeight" ref="cardTipFormular">
						<div slot="header" class="clearfix">
							<span>Model formular</span>
						</div>	
						<el-col > 
							<el-form-item label='Model formular' prop="IdTipFormular" >
								<el-select class='full-width' v-model="selectedObject.IdTipFormular" @change="selectTipFormular" :disabled="validationMode" filterable>
									<el-option v-for="item in Info.tipuri_formulare" :key="item.Id" :label="item.Nume" :value="item.Id"></el-option>
								</el-select>
							</el-form-item>
							<!-- <el-form-item label='Formular tip raspuns?'>
                                <el-checkbox class='full-width' v-model='selectedObject.EsteTipRaspuns' true-label='1' false-label='0' border >DA </el-checkbox>                                                   
                            </el-form-item> -->
							<el-form-item v-if="selectedObject.IdTipFormular == 71" label='Persoana inlocuitoare' prop="PersoanaInlocuitoare" >
								<el-select class='full-width' v-model="selectedObject.PersoanaInlocuitoare" :disabled="validationMode">
									<el-option v-for="item in Info.tipuri_formulare" :key="item.Id" :label="item.Nume" :value="item.Id"></el-option>
								</el-select>
							</el-form-item>
						</el-col> 						
					</el-card>
				</el-col>		

				<el-col :span='8' >
					<el-card :style="tabelListaHeight" ref="cardVariabile">
						<div slot="header" class="clearfix">
							<span>Variabile</span>
						</div>							
						<div v-if="selectedObject.Variabile.length > 0">
							<el-col  v-for="(item,index) in selectedObject.Variabile" :key='index' >
								<el-form-item v-if="item.Nume != 'semnatura'" :label='returnLabelName(item.Nume)' :prop="'Variabile.'+index+'.Value'" :rules="{ required: true, message: 'Campul este obligatoriu' }">
									<div v-if="item.Nume=='semnatura'" >
										<el-button type="success" v-if="permiteSalvareDocument" disabled plain> Semnat </el-button>										
										<el-button v-else :disabled="eroare_semnatura" @click="semneaza"> Semneaza </el-button>										
									</div>
									<!-- <div v-else-if="item.Nume=='data Document'"> -->
									<div v-else-if="item.Nume.includes('data') || item.Nume.includes('inceput') || item.Nume.includes('sfarsit')">
										<el-date-picker :disabled="validationMode" type="date" format="dd.MM.yyyy" value-format='yyyy-MM-dd' v-model="selectedObject.Variabile[index].Value" :picker-options="datePickerOptions" class='full-width'></el-date-picker>
									</div>
									<!-- <div v-else-if="item.Nume=='inceput concediu'">
										<el-date-picker :disabled="validationMode" type="date" format="dd.MM.yyyy" value-format='yyyy-MM-dd' v-model="selectedObject.Variabile[index].Value" :picker-options="datePickerOptions" class='full-width'></el-date-picker>
									</div>
									<div v-else-if="item.Nume=='sfarsit concediu'">
										<el-date-picker :disabled="validationMode" type="date" format="dd.MM.yyyy" value-format='yyyy-MM-dd' v-model="selectedObject.Variabile[index].Value" :picker-options="datePickerOptions" class='full-width'></el-date-picker>
									</div> -->
									<div v-else-if="item.Nume=='persoana inlocuitoare'">
										<el-select :disabled="validationMode" class='full-width' v-model="selectedObject.Variabile[index].Value" placeholder="Select" @change="seteazaPersoanaInlocuitoare(selectedObject.Variabile[index].Value)">
											<el-option v-for="item in Info.functii_angajati" :key="item.Id" :label="item.Nume" :value="item.Id"></el-option>
										</el-select>
									</div>
									<div v-else>										
										<el-input :disabled="(item.Nume=='numar Document' && mode=='edit') || validationMode" v-model="selectedObject.Variabile[index].Value" class='full-width' ></el-input>
									</div>
								</el-form-item>
							</el-col>
						</div>
						<div v-else>
							Selecteaza un model de formular!
						</div>
						
					</el-card>
				</el-col>		

				<el-col :span='8'>
					<el-card :style="tabelListaHeight" ref="cardOrdineRezolvare">	
						<div slot="header" class="clearfix">
							<span>Ordine rezolvare</span>
						</div>
						<div v-if="selectedObject.OrdineRezolvare.length > 0">
							<el-form-item >
								<el-table-draggable v-if="mode == 'add'" :animate="400">
									<el-table size="medium" :data="selectedObject.OrdineRezolvare">
										<el-table-column type="index" width="50px" label="#"></el-table-column>
										<el-table-column label="Nume" prop="Nume"></el-table-column>
										<el-table-column fixed="right" label="Actiuni" width="100px" >
											<template slot-scope="scope" >
												<el-tooltip content="Sterge" >
													<el-button :disabled="validationMode" size="mini" type="danger" icon="el-icon-delete" circle @click="delete_item(scope.$index, selectedObject.OrdineRezolvare)"  />
												</el-tooltip>
											</template>
										</el-table-column>
									</el-table>
								</el-table-draggable>
								
								<el-table v-else size="medium" :data="selectedObject.OrdineRezolvare">
									<el-table-column type="index" width="50px" label="#"></el-table-column>
									<el-table-column label="Nume" prop="Nume"></el-table-column>
									<el-table-column fixed="right" label="Actiuni" width="100px" >
										<template slot-scope="scope" >
											<el-tooltip content="Sterge" >
												<el-button :disabled="validationMode" size="mini" type="danger" icon="el-icon-delete" circle @click="delete_item(scope.$index, selectedObject.OrdineRezolvare)"  />
											</el-tooltip>
										</template>
									</el-table-column>
								</el-table>

							</el-form-item>	
							<el-form-item v-if="arataCampObservatii" label="Motiv modificare ordine">
								<el-input :disabled="validationMode" type="textarea" :rows="2" placeholder="please input" v-model="selectedObject.MotivSchimbareOrdineRezolvare"></el-input>
							</el-form-item>
						</div>
						<div v-else>
							Selecteaza un model de formular!
						</div>
					</el-card>
				</el-col>
			</el-row>
			<el-row :gutter="15" class="formular-draggable">

				<el-col :span='8'>
					<el-card v-show="mode == 'add'" :style="tabelListaHeight" ref="cardOrdineValidari">	
						<div slot="header" class="clearfix">
							<span>Pre Validari</span>
						</div>

						<div v-if="selectedObject.Variabile.length > 0">
							<el-checkbox v-model='selectedObject.PreValidari' true-label='1' false-label='0' border>Documentul necesita pre-validari</el-checkbox><br><br>
							<el-col>
								<el-form-item prop="" label="Selecteaza utilizatorii pentru validare">
									<el-select :filterable="true" v-if="selectedObject.PreValidari != '0'" class='full-width' v-model="selectedObject.OrdineValidari" :disabled="validationMode || disableValidariFormular" multiple>
										<el-option v-for="item in Info.Utilizatori" :key="item.Id" :label="item.Nume + '        ' + item.Email" :value="item.Id"></el-option>
									</el-select>
								</el-form-item>
							</el-col>	
						</div>
						<div v-else>
							Selecteaza un model de formular!
						</div>													
					</el-card>	
				</el-col>	
			</el-row>
			<el-row :gutter="20">
				<div class="container-fluid" id="test" style="border: 2px solid black" v-html="HTML1" v-show="false">
				</div>
			</el-row>

			<el-row :gutter="20">
				<el-col>					
					<el-form-item label='Continut' prop="Continut">
						<summernote
						:key="ttt"
						ref="summernote" 
						class="form-control"
						height="500"
						:arata_variabile="true"
						name="formular_template_content"
						:model.sync="selectedObject.Continut"
						@addVariable="showAddVariable"></summernote>
					</el-form-item>										
				</el-col>  				
			</el-row>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<span v-if="!permiteSalvareDocument" class="alerta">Atentie, documentul nu este semnat!</span>
			<el-button @click="resetForm('selectedObject')" > Renunta  </el-button>
			<el-button v-if="!validationMode" :disabled="!permiteSalvareDocument" type="primary" @click="save()" > Salveaza </el-button>
			<el-button v-else type="success" :disabled="eroare_semnatura" @click="semneaza"> Semneaza </el-button>
			<!--<el-button type="success" @click="genereazaPdf" > Vezi PDF </el-button>-->
		</span>

		<el-dialog title="Login" :visible.sync="showPopupSemnatura" width="30%" :append-to-body="true">			
			<div v-if="eroare_semnatura">
				<span class="eroare">Nu exista semnatura incarcata</span>
			</div>
			<div v-else>
				<img width="70%" :src="imagineSemnatura" />			

				<el-form :inline="true" :rules="rules" :model='obiectParola' ref='verificareParola' @submit.prevent="verificaParola" v-loading="loader" >					
					<el-form-item label="Parola">
						<el-input type="password" v-model="obiectParola.password" placeholder='Parola dvs' class='full-width'></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="verificaParola"> Valideaza semnatura </el-button>
					</el-form-item>					
				</el-form>
			</div>
		</el-dialog>

		<addVariable ref="addVariable"/>
		<bpImageUpload/>
		
  	</el-dialog>
</template>

<script>

import { VueEditor, Quill } from "vue2-editor"
import $ from 'jquery'
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'
import FormularEditor from './FormularEditor'
import Summernote from './Summernote'
import AddVariable from './AddVariable'
import BpImageUpload from './BpImageUpload'
import UploadDocumente from '@/components/UploadDocumente'
import draggable from 'vuedraggable'
import ElTableDraggable from 'element-ui-el-table-draggable';
import moment from 'moment';

export default {
    Nume: "Formular_dialog",
    extends: BasePage,    
    components: {
      formularEditor: FormularEditor,
      summernote: Summernote,
      addVariable: AddVariable,
      bpImageUpload: BpImageUpload,
	  draggable,
	  ElTableDraggable,
	  UploadDocumente
    },
	props:{
		validationMode:false
	},
	computed:{
		permiteSalvareDocument(){
			var ret = true
			if(this.selectedObject.Variabile.length > 0){
				this.selectedObject.Variabile.forEach(v=>{
					if(v.Nume == 'semnatura' && parseInt(v.Value) > 0){
						ret = true
					}						
				})
			}
			return ret
		},
		arataCampObservatii(){
			if(this.mode == 'add' && this.ordineSchimbata)
				return true
			if(this.mode == 'edit')
				return true
		}
	},
    data () {
      	return {
			datePickerOptions: {
				disabledDate (date) {
					return date <= new Date()
				}
			},
			showPopup: false,
			mode: 'add',
			selectedObject: { IdTipFormular: '', NumarZileSolutionare:'', Continut: '', OrdineRezolvare:[], Variabile:[], MotivSchimbareOrdineRezolvare:'', fileList:[], OrdineValidari: [], info: '', EsteTipRaspuns:'', PreValidari: 0},  
			obiectParola: {password:''},               
			Info:{ tipuri_formulare: [], functii_angajati:[], Utilizatori: [] },
			rules: {
				IdTipFormular:				[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'  }],
				Continut: 					[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }],
				NumarZileSolutionare: 		[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }],
				GrupUtilizatoriAsociati:	[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }]
			},
			multipleSelection: [],
			tabelListaHeight:{},
			eroare_semnatura: false,
			showPopupSemnatura: false,
			loader: false,
			imagineSemnatura: '',
			idUser: '',
			numeUtilizator:'',
			prenumeUtilizator:'',
			ordineRezolvareStandard:[],
			ordineSchimbata: false,
			disableValidariFormular: false,
			baseUrl:'',
			idFormular: -1,
			HTML: '',
			HTML1: '',
			User: {},
			from: '',
			semnat: false,
			ttt: 0
      }
    },
    methods: {	
    	show_me: async function( id, from = '' ) {

			this.Id = id
          	this.selectedObject 	= { IdTipFormular: '', NumarZileSolutionare:'', Continut: '', OrdineRezolvare:[], Variabile:[], MotivSchimbareOrdineRezolvare:'', fileList:[], OrdineValidari: [], info: '', EsteTipRaspuns:'', PreValidari: 0 }
			this.eroare_semnatura	= false
            this.showPopup    		= true;
			// this.showPopupSemnatura = false
			this.imagineSemnatura 	= ''
			this.password 			= ''
			this.idUser				= ''
			this.ordineRezolvareStandard =[]
			this.ordineSchimbata 	= false	
			this.from = from
			
			await this.getFunctieUtilizator();

            if( id == null )
            {
                this.mode = "add";
				this.idFormular = -1
            }
            else
            {
				this.idFormular 	= id
                this.mode			= "edit";
				this.disableValidariFormular = true;
                const result 		= await this.post("formulare/get_info_item_dialog", { id: id } );
                this.selectedObject	= result.Item;
				console.log(this.selectedObject);
				console.log(result.Item);
				this.ttt++;
				if(this.validationMode){
					$('#summernote').summernote('disable');
				}
				// console.log(this.selectedObject.PreValidari);
				// this.selectedObject.PreValidari = this.selectedObject.PreValidari == '1' ? 1 : 0;

				if(this.selectedObject.OrdineRezolvare.length == 0){
					var filtered						= this.Info.tipuri_formulare.filter(t => t.Id == this.selectedObject.IdTipFormular)
					this.selectedObject.OrdineRezolvare	= filtered[0].OrdineRezolvare 
				}

				this.selectTipFormular()
				this.$forceUpdate()
            }
			
            this.$nextTick(() => {
				setTimeout(() => this.matchHeight(), 50);
            })
        },		
        async get_info(){
            var response				= await this.post("formulare/get_info_for_dialog" );

			this.Info.Utilizatori       = response.Utilizatori;
			this.Info.functii_angajati	= response.functii_angajati;
			this.Info.tipuri_formulare	= response.tipuri_formulare//.filter(formular => formular.EsteActiv == "1");
			this.seteazaNumePrenumeUtilizator(response.User)
        },
		seteazaNumePrenumeUtilizator(user){
			var parts = user.Nume.split(" ")
			
			if(parts.length >= 2)
			{
				this.numeUtilizator 	= parts[1]
				this.prenumeUtilizator 	= parts[0]
			}
		},
        async save() {			
			this.$refs['selectedObject'].validate( async(valid) => {
				if (valid)
				{
					this.reindexareOrdineRezolvare()
					let verifyDocId = await this.post("formulare/verify_doc_id", {object: this.selectedObject } );
					this.selectedObject.info = '';
					if(verifyDocId != this.selectedObject.Variabile[0].Value) {
						await this.post("formulare/save", { mode: this.mode, object: this.selectedObject } );
						this.resetForm()
						this.$emit("save");
					} else {
                    	this.$message({ message: 'Documentul nu s-a putut salva cu numarul curent. Incearca din nou.',type: 'warning'});
						this.selectedObject.Variabile[0].Value++;
					}
			
				} 
				else 
					{console.log('eroare validare formular!');}
			});                
		},
		reindexareOrdineRezolvare(){
			this.selectedObject.OrdineRezolvare.forEach(				
				(o,index) => {
					o.NumarOrdine = index + 1
				}
			)
		},
		resetForm() {
			this.$refs['selectedObject'].resetFields()
			this.showPopup = false
		},
        showAddVariable() {
          	this.$refs.addVariable.show()
        },
		async delete_item( index, rows ){
            if( await this.$confirm(`Esti sigur ?`, 'Warning') ){
				rows.splice(index, 1);
				this.selectedObject.OrdineRezolvare = rows;
				this.creeazaTabelSemnaturi(this.selectedObject);
            }
        },
		adaugaInLista(){
			this.selectedObject.OrdineRezolvare = this.multipleSelection
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		rowClicked(row){
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		matchHeight() {				
            this.$set(this.tabelListaHeight, 'min-height', this.getBiggestCardHeight()+'px'); 
        },
		getBiggestCardHeight(){
			let heightsArr = [this.$refs.cardTipFormular.$el.clientHeight,this.$refs.cardVariabile.$el.clientHeight,this.$refs.cardOrdineRezolvare.$el.clientHeight, this.$refs.cardOrdineValidari.$el.clientHeight ]
			return Math.max.apply(null, heightsArr);		
		},
		async selectTipFormular(){
			console.log('apasat');
			if(this.mode=='add')
			{				
				var filtered 								= this.Info.tipuri_formulare.filter(t => t.Id == this.selectedObject.IdTipFormular)
				this.selectedObject.NumarZileSolutionare	= filtered[0].NumarZileSolutionare
				this.selectedObject.OrdineRezolvare 		= filtered[0].OrdineRezolvare
				this.ordineRezolvareStandard				= JSON.parse(JSON.stringify(filtered[0].OrdineRezolvare));
				this.selectedObject.Variabile 				= filtered[0].Variabile							

				if(this.ordineRezolvareStandard.length > 0){
					await this.creeazaTabelSemnaturi(filtered[0]);
					let indexTabelSemnaturi = settings.getIndexOfArrayProperty(this.selectedObject.Variabile,'Nume','tabel semnaturi');
					let indexSemnatura = settings.getIndexOfArrayProperty(this.selectedObject.Variabile,'Nume','semnatura');
					
					if(indexTabelSemnaturi > 0)
						this.selectedObject.Variabile.splice(indexTabelSemnaturi,1);
					
					if(indexSemnatura > 0)
						this.selectedObject.Variabile = await settings.moveIndexToEnd(this.selectedObject.Variabile,indexSemnatura);
				}
				
			}else { 
				if(this.validationMode){
					this.HTML1 = this.selectedObject.Continut
					await this.$nextTick()
					this.selectedObject.Continut = document.getElementById("test").innerHTML
				}				
			}
			setTimeout(() => this.matchHeight(), 50);	
		},
		seteazaPersoanaInlocuitoare(id){
			let filter = this.Info.functii_angajati.filter(f => f.Id == id )
			let objToPush = {Id:'-1', IdFunctieAngajat: filter[0].Id, Nume: filter[0].Nume, NumarOrdine: '-1'}
			this.selectedObject.OrdineRezolvare.unshift(objToPush);

			this.creeazaTabelSemnaturi(this.selectedObject);
		},		
		async getFunctieUtilizator(){
			const response = await this.$http.get('utilizatori/get_functie_utilizator')
			this.User = response.body.User
		},
		returnLabelName(item){
			item 	= item.toLowerCase()
			var ret	= item.charAt(0).toUpperCase() + item.slice(1)

			return ret
		},
		genereazaPdf() {
        //   window.open("http://ananp.brk-dev.ro/api/index.php/formulare/generate_pdf/" + this.selectedObject.Id, '_blank');
          window.open(settings.BASE_URL + "formulare/generate_pdf/" + this.selectedObject.Id, '_blank');
        },
		async creeazaTabelSemnaturi(form){
			this.HTML1 = form.Continut

			await this.$nextTick()

			this.HTML = "<div>"
			this.HTML += `<table style="width: 50%;">`

			this.selectedObject.OrdineRezolvare.forEach(ordin => {
				this.HTML += "<tr><td>" + ordin.Nume + "</td>";
				this.HTML += `<td><span class="numePrenume" id="${ordin.IdFunctieAngajat}-nume-prenume">--</span>`;
				this.HTML += `<td><span class="semnatura" id="${ordin.IdFunctieAngajat}" style="display:inline-block">**</span>`;
			})
			this.HTML += `</tr>
				</table></div>`;

			if(document.getElementById('tabelSemnaturi') != null){
				document.getElementById('tabelSemnaturi').innerHTML = this.HTML;
				this.selectedObject.Continut = document.getElementById('test').innerHTML;
			}else {
				this.selectedObject.Continut = form.Continut;
			}
		},	
		async semneaza(){
			this.showPopupSemnatura	= true
			this.loader 			= true
			var response 			= await this.verificaSemnatura()
			this.imagineSemnatura	= response.Semnatura
			this.idUser				= response.UserId

			if(response.Error)
				this.eroare_semnatura = true

			this.loader = false
		},
		async verificaSemnatura(){
			var result 		= await this.post('utilizatori/verifica_semnatura');
			if(result.Error)
                    this.eroare_semnatura = true
			// result.Error	= false

			// if(result.Semnatura == 'undefined' || result.Semnatura == '')
			// 	result.Error = true

			return result
		},
		async verificaParola(){
			var result = await this.post('utilizatori/verifica_parola', {password: this.obiectParola.password})
			if(!result.Error){
				this.setezaIdSemnatura()
				this.showPopupSemnatura = false
				await this.semneazaValidarea()
			}
			else
				this.$message.error('Parola gresita');
		},
		async getUltimulId(){
			var result = await this.post('formulare/get_ultimul_id_formular')
			return parseInt(result.ultimulId)
		},		
		setezaIdSemnatura(){
			this.semnat = true;	
			this.selectedObject.Variabile.forEach(v=>{
				if(v.Nume == 'semnatura')
					v.Value = this.idUser
			})
		},
		seteazaDataCurenta(index){
			this.selectedObject.Variabile[index].Value = moment().format("YYYY-MM-DD")
		},
		seteazaDataDocument(index){
			if(this.mode == 'add')
				this.selectedObject.Variabile[index].Value = moment().format("YYYY-MM-DD")
		},
		seteazaPrenumeSolicitant(index){
			this.selectedObject.Variabile[index].Value = this.prenumeUtilizator
		},
		seteazaNumeSolicitant(index){
			this.selectedObject.Variabile[index].Value = this.numeUtilizator
		},
		async seteazaNumarDocument(index){
			if(this.mode == 'add')
			{
				var ultimulId 								= await this.getUltimulId()
				this.selectedObject.Variabile[index].Value 	= ultimulId + 1
			}
		},
		incarcaDocumente(){
			this.$refs.uploadDoc.showMe()
		},
		setFileName(filename){
			this.selectedObject.fileList.push(filename)
		},
		setImgUrl(){
			var url = settings.BASE_URL;
			url = url.replace('index.php/','')
			this.baseUrl = url;
		},
		async semneazaValidarea(){
			this.mode = 'edit';
			this.selectedObject.info = 'semnatura';
			await this.post("formulare/save", { mode: this.mode, object: this.selectedObject } );
			this.$emit('formularValidat');
			this.resetForm();
		}
    },
    mounted: function() {
      this.get_info();
	},
	watch: {
		async semnat(newVal, oldVal){
			if(document.getElementById(`${this.User.IdFunctieAngajat}`) != null && this.from != 'edit'){
				document.getElementById(`${this.User.IdFunctieAngajat}`).innerHTML = `<img src="${this.User.Semnatura}" width="100px" height="100px"/>`;
				document.getElementById(`${this.User.IdFunctieAngajat}` + "-nume-prenume").innerHTML = `<span>${this.User.Nume}</span>`;
				this.$nextTick();
			}
			this.selectedObject.Continut = document.getElementById("test").innerHTML
		},
		'selectedObject.Variabile'(val){
			if(val.length > 0)
			{
				val.forEach((v,index)=>{
					if(v.Nume == 'numar Document')
						this.seteazaNumarDocument(index)
					if(v.Nume == 'data Document')
						this.seteazaDataDocument(index)
					if(v.Nume == 'prenume solicitant')
						this.seteazaPrenumeSolicitant(index)
					if(v.Nume == 'nume solicitant')
						this.seteazaNumeSolicitant(index)
					if(v.Nume == 'data curenta'){
						this.seteazaDataCurenta(index);
					}
				})			
			}
		},
		'selectedObject.OrdineRezolvare'(val){
			if(this.mode == 'add' && val.length > 0){
				this.ordineSchimbata = false
				for(var i=0;i<this.ordineRezolvareStandard.length;i++){
					if(val[i].Id == '-1'){
						this.ordineSchimbata = true
						continue;
					}
					if(val[i].Id != this.ordineRezolvareStandard[i].Id){
						this.ordineSchimbata = true		
						this.creeazaTabelSemnaturi(this.selectedObject);
					}										
				}
			}
		}	
	}
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
.alerta{
	color: red;
	font-size: 15px;
	margin-right: 10px;
}

.image_preview{
	width: 150px;
	display: block;
	border: solid 1px grey;
}

.formular-draggable{
	margin-bottom: 20px;
	.list {
        background-color: #EBEEF5;
        font-size: 14px;
        .header.el-row {
            background-color: #FFF;
            color: #909399;
            font-weight: 500;            
        }
        .el-row {
            background-color: #FFF;
            transition: background-color .25s ease;
            border-bottom: 1px solid #EBEEF5;
            color: black;
            height: 40px;
            .el-col {
                border-bottom: 1px solid #EBEEF5;
                padding-top: 5px;
                padding-bottom: 5px;
                height: 100%;
                padding-left: 10px;
                padding-right: 10px;
                min-width: 0;
                display: flex;
                
                align-items: center;
                line-height: normal;
            }
        }
    }

	.card-tabel-lista{
		margin-bottom:20px;
	}
	.card-principal{
		vertical-align: middle
	}
	.buton-adauga-tabel{
		position: absolute;
    	top: 50%;
	}

	.eroare {
		color: red;
		font-size: 12
    }	
}

</style>