

<template>
    <el-dialog :visible.sync="dialogVisible" width="70%">
        <el-card style="margin-bottom:15px">  
            <div slot="header" class="clearfix">
                <span>Upload documente</span>
            </div>      
            <el-upload
                :action="baseUrl + 'formulare/upload_documente?id=' + formularId"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleUploadSuccess"
                multiple>
                <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="previewVisible" append-to-body>                
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </el-card>

        <el-card>
            <div slot="header" class="clearfix">
                <span>Documente incarcate</span>
            </div>  
            <el-table :data="documente">
                <el-table-column label="#" type='index' width="90"></el-table-column>
                <el-table-column label="Data incarcare" prop="DataIncarcare"></el-table-column>
                <el-table-column label="Utilizator" prop="Utilizator"></el-table-column>
                <el-table-column label="Document">
                    <template slot-scope="scope">
                        <div v-if="scope.row.fileType=='pdf'">
                            <embed :src="scope.row.imgUrl" class="image_preview" />
                        </div>
                        <div v-else>
                            <img :src="scope.row.imgUrl" alt="document" class="image_preview">
                        </div>                        
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actiuni">
                    <template slot-scope="scope">
                        <el-tooltip content="Sterge" >
                            <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row.Id)" />
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <span slot="footer" class="dialog-footer">			
			<el-button @click="dialogVisible=false" > Renunta  </el-button>
            <el-button type="success" @click="incarcaDocumente()" > Finalizeaza  </el-button>
		</span>
    </el-dialog>
   
        
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    export default {
        name: "UploadDocumente",      
        extends: BasePage,  
        data() {
            return {
                baseUrl:'',
                showPopupDocumente:false,
                dialogImageUrl: '',
                dialogVisible: false,
                previewVisible:false,
                formularId:'',
                fileList:[],
                documente:[]
            };
        },
        methods: {
            showMe(id){
                this.formularId = id
                this.dialogVisible = true;

                this.getDocuments()
            },
            async getDocuments(){
                var response    = await this.post("formulare/get_documente", { idFormular: this.formularId } )
                this.documente  = response.Intrari
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
            },
            handleUploadSuccess(response){
                this.fileList.push(response.FileName);
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.previewVisible = true;
            },
            async incarcaDocumente(){
                var response = await this.post("formulare/incarca_documente", { idFormular: this.formularId, fileList: this.fileList } )
                if(response.Eroare=='1' || response.Eroare)
                    this.$message({ message: 'Documentul nu s-a putut salva.',type: 'warning'});
                else
                    this.$message({message: 'Documentele au fost salvate cu succes!', type: 'success'});  
                    
                this.dialogVisible = false
            },
            setImgUrl(){
                var url = settings.BASE_URL;
                this.baseUrl = url;
            },
            async delete_item(id){
                this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                    type: 'warning'
                    }).then(async() => {
                        await this.post("formulare/sterge_document", {Id:id} );
                        this.getDocuments(); 
                        this.$message({type: 'sucess', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                    });

                // var response = await this.post("formulare/sterge_document", {Id:id})
                // return
                // if(response.Eroare !='1' || !response.Eroare)
                //     this.$message({ message: 'Documentul a fost sters cu succes!',type: 'success'});
                // else
                //     this.$message({message: 'Documentul nu a putut fi sters!', type: 'warning'});  
            }
        },
        mounted() {
            this.setImgUrl()
        }
    }
</script>

<style lang="less" scoped>

.image_preview{
	width: 150px;
    height: 150px;
	display: block;
	border: solid 1px grey;
}
.el-upload__input {
    display: none!important;
}

</style>