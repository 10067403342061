<template>
  <el-dialog title="Upload" :visible.sync="showPopup" append-to-body width="75%" :close-on-click-modal='false'>
    <el-form label-position="top" @submit.prevent="save()">
      <el-upload
        class="logo-uploader"
        :action="uploadUrl"
        :show-file-list="false"
        :on-success="handleSuccess">
        <img v-if="ImageUrl" :src="ImageUrl" class="img-responsive logo-preview">
        <i v-else class="el-icon-plus logo-uploader-icon"></i>
      </el-upload>
    </el-form> <!-- /form -->
    <span slot="footer" class="dialog-footer" >
      <el-button @click="showPopup = false">Cancel</el-button>
      <el-button type="primary" @click="save" > Save </el-button>
    </span>
  </el-dialog><!-- /.modal -->
</template>

<script>
import $ from 'jquery'
import settings from "@/backend/LocalSettings";
import { EventBus } from '../../main'
// import Constants from '../../backend/Constants'

export default {
  name: 'BpImageUpload',
  props: [],
  data:  function() {
    return {
      name: "",
      domElement: {},
      summerNoteContext: {},
      focused_element: {},
      ImageUrl: "",
      LoadingVisible: false,
      showPopup: false,
      uploadUrl: settings.BASE_URL + 'Image_uploader/upload'
    }
  },
  methods: {
    show_me: function ( domElement, summerNoteContext ) {
      this.domElement         = domElement;
      this.summerNoteContext  = summerNoteContext;
      this.focused_element    = window.getSelection().focusNode;

      this.showPopup = true
    },
    save: function () {
      this.showPopup = false
      var newElement = document.createElement('img');

      $(newElement).attr("src",this.ImageUrl);

      var range   = document.createRange();
      var sel     = window.getSelection();

      range.setStart(this.focused_element, this.focused_element.length); // put the cursor in last position of the element
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);

      $("#summernote").summernote('focus')
      $("#summernote").summernote('insertNode', newElement)
    },
    handleSuccess: function(res,file){
      var response = file.response;
      if( response.HasError == "1" ){
        this.$message.error(response.Error);
      }else{
        this.ImageUrl  = response.Url;
      }
    }
  },
  created: function() {
    var me = this;
    EventBus.$on('show_image_upload_dialog', function( domElement, summerNoteContext )
    {
        me.show_me( domElement, summerNoteContext );
    })
  }
}
</script>