<template>
  <textarea id="summernote" ref="textarea" :name="name"></textarea>
</template>
<script>
import BpVarEdit from './BpVarEdit'
import BpImageUpload from './BpImageUpload'
// import AddVariable from './AddVariable'

/*
"jquery": "^3.4.1",
"popper.js": "1.14.3",
"summernote": "^0.8.1",
*/


import { EventBus } from '../../main'

import $ from 'jquery'

import 'bootstrap'
require('summernote')

import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'

export default {
  name: "Summernote",
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '150'
    },
    arata_variabile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: 0,
      innerChangeTimeout: null,
      focused: false,
      summernote: undefined,
      headInserted: []
    }
  },
  watch: {
    model (newVal, oldVal) {
      if (this.status == 0) {
        $(this.$refs.textarea).summernote("code", newVal)
      } else {
        this.status = 0
      }
      // $(this.$refs.textarea).summernote("code", newVal)
    }
  },
  methods: {
    refresh() {
      $(this.$refs.textarea).summernote("code", this.model)
    },
    emit_new_html ( vm, html ) {
      vm.status = 1
      vm.$emit('update:model' , html)
      vm.$emit('change'       , html)
      //
      if (vm.innerChangeTimeout != null) {
        clearTimeout(vm.innerChangeTimeout)
      }
      vm.innerChangeTimeout = setTimeout(() => {
        vm.status = 0
      }, 300)
    },
    pageBreakButton (context) {
      var ui = $.summernote.ui

      var onClick = () => {
        var div = document.createElement('div')
        div.className = "page-break"       
        $('#summernote').summernote('insertNode', div)
      }

      // create button
      var button = ui.button({
        contents: '<i class="fa fa-child"/> PageBreak',
        tooltip: 'PageBreak',
        click: onClick
      })

      return button.render();   // return button as jquery object
    },
    imageUpload (context) {
      var ui = $.summernote.ui

      // create button
      var button = ui.button({
          contents: '<i class="fa fa-cloud-upload"/> Upload',
          tooltip: 'Image upload',
          click: () => {
            var domEl = $(this)
            EventBus.$emit("show_image_upload_dialog", domEl, context)
          }
      })

      return button.render()   // return button as jquery object
    },
    formFieldButton (context) {
      var ui = $.summernote.ui

      let vm = this

      
    },
  },
  created () {
    var node = document.createElement('script')
    node.setAttribute('src', 'http://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.js')
    document.head.appendChild(node)
    this.headInserted.push(node)

    node = document.createElement('link')
    node.setAttribute('href', 'http://netdna.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.css')
    node.setAttribute('rel', 'stylesheet')
    document.head.appendChild(node)
    this.headInserted.push(node)

    node = document.createElement('script')
    node.setAttribute('src', 'http://netdna.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.js')
    document.head.appendChild(node)
    this.headInserted.push(node)

    node = document.createElement('script')
    node.setAttribute('src', 'http://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.12/summernote.js')
    document.head.appendChild(node)
    this.headInserted.push(node)

    node = document.createElement('link')
    node.setAttribute('href', 'http://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.12/summernote.css')
    node.setAttribute('rel', 'stylesheet')
    document.head.appendChild(node)
    this.headInserted.push(node)
  },
  mounted () {
    var config = {
      height: this.height
    }

    var vm = this

    config.toolbar = [
      ['para', ['style','ul', 'ol', 'paragraph']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['insert', ['table','picture','link','video']],
      ['misc', ['fullscreen','codeview','help']],
      ['pbbreak', ['PageBreakBtn','FormFieldButton', 'ImageUpload']]
    ]

    config.buttons = {
      PageBreakBtn: this.pageBreakButton,
      ImageUpload: this.imageUpload,
    }

    if (this.arata_variabile) {
      config.buttons.FormFieldButton = this.formFieldButton
    }

    config.callbacks = {
      onInit (context) {
        $('#summernote').summernote("code", vm.model)
      },
      onChange () {
        vm.emit_new_html(vm, $('#summernote').summernote('code'))
      },
      onFocus () {
      },
      onBlur () {
        vm.emit_new_html(vm, $('#summernote').summernote('code'))
        $('#summernote').summernote('saveRange')
      },
      onBlurCodeview () {
        $('#summernote').summernote('saveRange')
      }
    }

    this.summernote = $('#summernote')
    this.summernote.summernote(config)
  },
  beforeDestroy () {
    this.summernote.summernote('destroy')
  },
  destroyed() {
    this.headInserted.forEach(element => {
      document.head.removeChild(element)
    })
  },
  components: {
    bpVarEdit: BpVarEdit,
    bpImageUpload: BpImageUpload,
    // addVariable: AddVariable
  }
}
</script>

<style lang="less">
  h1 {
    font-size: 2.5rem;
  }

  .full-width {
    width: 100%;
  }

  .note-editor .dropdown-toggle::after {
    display: none;
  }

  .note-editor .variabila {
    border: 1px solid red;
    background:red;
    color: white;
  } 
  .buton-verde{
    background-color: red!important;
    color:white !important;
  }
</style>